/**
 * @module contexts/AppStateProvider
 * @author Charles Blais <charles.blais@canada.ca>
 */

import React, { createContext, useContext, useReducer } from "react";

/**
 * Application state variables
 * ---------------------------
 */

type AppState = {
    baseUrl: string,
}

export const initialStateProduction: AppState = {
    baseUrl: '/.rave/',
};

export const initialStateDevelopment: AppState = {
    baseUrl: 'https://eqcan-dev.seismo.nrcan.gc.ca/.rave/test/',
};

type ActionSetBaseUrl = {
    type: 'SET_BASE_URL',
    payload: string,
}

type AppStateAction = ActionSetBaseUrl;


const reducer = (
    state: AppState,
    action: AppStateAction,
): AppState => {
    switch (action.type) {
        case 'SET_BASE_URL':
            return {
                ...state,
                baseUrl: action.payload,
            }
        default:
            return state;
    }
}

/**
 * React useContext hook
 * ---------------------
 */
type AppContextProps = [
    AppState,
    React.Dispatch<AppStateAction>,
]

export const AppContext = createContext<AppContextProps>([initialStateProduction, () => {}]);

type AppStateProviderProps = {
    initialState: AppState,
    children: React.ReactNode,
}

export const AppStateProvider = ({
    initialState,
    children
}: AppStateProviderProps) => (
    <AppContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </AppContext.Provider>
);

export const useAppState = () => useContext(AppContext);
