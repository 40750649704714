/**
 * @author Charles Blais <charles.blais@nrcan-rncan.gc.ca>
 */
import { useEffect } from 'react';

import Headline from '../components/headline';

import useAlerts from '../hooks/use-alerts';

import { useAppState } from "../contexts/app-state-provider";



const ContainerHeadlines = () => {

    const [ state ] = useAppState();

    const { alerts, setUrl }= useAlerts({
        initialUrl: state.baseUrl,
    });

    // if the base URL changes, retrieve new headlines
    useEffect(() => {
        setUrl(state.baseUrl);
    }, [state.baseUrl, setUrl]);

    if(!alerts) {
        // show nothing of the panel as there are no headlines
        return null;
    }

    const headlines = alerts.map((alert, idx) => <Headline key={idx} alert={alert} />)

    return <>{headlines}</>;
}


export default ContainerHeadlines;
