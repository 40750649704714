/**
 * @module components/maps/control-legened
 * @author Charles Blais <charles.blais@nrcan-rncan.gc.ca>
 * 
 * Custom control for legend
 */

import CustomControl from './custom-control';

import { ControlPosition } from 'leaflet';


type Props = {
    children?: React.ReactNode,
    position?: ControlPosition,
}


/**
* React component
*/
const ControlLegend = ({
    children,
    position = 'bottomright',
}: Props) => {
    return (
        <CustomControl position={position} className="leaflet-legend">
            {children}
        </CustomControl>
    );
};


export default ControlLegend; 
