/**
 * @module contexts/language-state-provider
 * @author Charles Blais <charles.blais@canada.ca>
 */

import React, { createContext, useContext, useReducer } from "react";

/**
 * Language state States
 * ---------------------------
 */
export type LanguageState = {
    abbr: string,
    earthquake: {
        origin: string,
        area: string,
    }
}

export const initialStateEnglish: LanguageState = {
    abbr: 'en',
    earthquake: {
        origin: 'epicentre',
        area: 'strong shaking anticipated',
    }
}

export const initialStateFrench: LanguageState = {
    abbr: 'fr',
    earthquake: {
        origin: 'épicentre',
        area: 'fortes secousses attendues',
    }
}

/**
 * Reducer settings
 * ----------------
 */
type ActionSetLanguage = {
    type: 'SET_LANGUAGE',
    payload: 'en' | 'fr',
}

type LanguageStateAction = ActionSetLanguage;

const reducer = (
    state: LanguageState,
    action: LanguageStateAction,
): LanguageState => {
    switch (action.type) {
        case 'SET_LANGUAGE':
            switch (action.payload) {
                case 'fr':
                    return initialStateFrench;
                default:
                    return initialStateEnglish;
            }
        default:
            return state;
    }
}

/**
 * React useContext hook
 * ---------------------
 */
type LanguageContextProps = [
    LanguageState,
  React.Dispatch<LanguageStateAction>,
]

export const LanguageContext = createContext<LanguageContextProps>([initialStateEnglish, () => {}]);

type LanguageStateProviderProps = {
    initialState: LanguageState,
    children: React.ReactNode,
}

export const LanguageStateProvider = ({
    initialState,
    children,
}: LanguageStateProviderProps) => (
    <LanguageContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </LanguageContext.Provider>
);

export const useLanguageState = () => useContext(LanguageContext);
