/**
 * @author Charles Blais
 */

import { ControlPosition } from 'leaflet';

 // Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
}
  

type Props = {
    children?: React.ReactNode,
    className?: string,
    position?: ControlPosition,
}


const CustomControl = ({
    children,
    position = 'bottomright',
    className = ''
}: Props) => {
    const positionClass =
        (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    return (
        <div className={positionClass}>
            <div className={`leaflet-control leaflet-bar ${className}`}>
                {children}
            </div>
        </div>
    )
};

export default CustomControl;
