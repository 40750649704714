/**
 * @module components/headline
 * @author Charles Blais <charles.blais@nrcan-rncan.gc.ca.ca>
 * 
 * Panel for download content
 */

import { Row, Col, Alert } from 'react-bootstrap';

import { Alert as CAPAlert } from '../typings/cap';

import { useLanguageState } from "../contexts/language-state-provider";

import AlertMap from './maps/alert-map';

import Markdown from 'react-markdown'


type Props = {
    alert: CAPAlert,
}


const Headline = ({
    alert
}: Props) => {
    const [ lang ] = useLanguageState();

    const index = (lang.abbr === 'fr')?1:0;

    // TODO: bug in french to be fixed later
    const description = alert.info[index].description?.replace('ŕ', 'à');
    if(description === undefined || description.length === 0) return null;

    let style = "info";
    switch(alert.info[index].severity) {
        case "Extreme":
        case "Severe":
            style = "danger";
            break;
        case "Moderate":
            style = "warning";
            break;
        default:
            style = "info";
            break;
    }

    const has_shape = alert.info[0].area?.find(area => area.polygon || area.circle);
    if(!has_shape) {
        return (
            <Alert bsStyle={style}>
                <Row><Col sm={12}><Markdown>{description}</Markdown></Col></Row>
            </Alert>
        ) 
    }

    return (
        <Alert bsStyle={style}>
            <Row>
                <Col sm={6}>
                    <Markdown>{description}</Markdown>
                </Col>
                <Col sm={6}>
                    <AlertMap alert={alert} />
                </Col>
            </Row>
        </Alert>
    ) 
}

export default Headline;
  