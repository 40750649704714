/**
 * @author Charles Blais <charles.blais@nrcan-nrcan.gc.ca>
 * 
 */
import { useState, useEffect, useMemo } from 'react';

import useAxios from 'axios-hooks'

import { AxiosResponse, AxiosError } from 'axios';

import { Alert } from '../typings/cap';


type Props = {
    initialUrl?: string,
}

export type Response = {
    alerts?: Alert[],
    loading: boolean,
    error: AxiosError | undefined,
    response: AxiosResponse | undefined,
    setUrl: (value: string) => void,
};


const useAlerts = ({
    initialUrl = '',
}: Props): Response => {

    const [ url, setUrl ] = useState(initialUrl);
  
    const [{ data, loading, error, response }, refetch] = useAxios({
      url,
    }, {
      manual: initialUrl.length === 0
    });

    // if the search has changed state, refetch the new geolocation
    useEffect(() => {
      if(url.length) {
        refetch({ url })
      }
    }, [url, refetch]);

    const alerts = useMemo<Alert[]|undefined>(() => {
      if (data === undefined) return undefined;
      return data;
    }, [data]);

    return {
      alerts,
      loading,
      error,
      response,
      setUrl,
    }
}

export default useAlerts;
