/**
 * @module components/maps/control-legened
 * @author Charles Blais <charles.blais@nrcan-rncan.gc.ca>
 * 
 * Custom control for legend
 */

import ControlLegend from './control-legend';

import { ControlPosition } from 'leaflet';

import earthquakeIcon from '../../data/star.png';

import { useLanguageState } from '../../contexts/language-state-provider';


type Props = {
    position?: ControlPosition,
    showMarker?: boolean,
    showPolygon?: boolean,
}


/**
* React component
*/
const ControlLegendEarthquake = ({
    position = 'bottomright',
    showMarker = false,
    showPolygon = false,
}: Props) => {

    const [ lang ] = useLanguageState();

    if(!showMarker && !showPolygon) {
        return null;
    }

    return (
        <ControlLegend position={position}>
            <ul className='no-bullets'>
                {showMarker &&
                    <li><img alt="" src={`${earthquakeIcon}`} style={{width:'20px'}}/> {lang.earthquake.origin}</li>
                }
                {showPolygon &&
                    <li><span className='circle-area' /> {lang.earthquake.area}</li>
                }
            </ul>
        </ControlLegend>
    );
};


export default ControlLegendEarthquake; 
