/**
 * @module components/maps/alert-map
 * @author Charles Blais <charles.blais@nrcan-nrcan.gc.ca>
 */
import CanadaMapContainer from "./canada-map-container";

import { Polygon, Marker } from "react-leaflet";

import L, { LatLng } from "leaflet";

import ControlLegendEarthquake from "./control-legend-earthquake";

import { Alert as CAPAlert, Area } from '../../typings/cap';

import earthquakeIcon from '../../data/star.png';


type Props = {
    alert: CAPAlert,
}


export const leafletEarthquakeIcon = L.icon({
    iconUrl: earthquakeIcon,
    iconSize: [32, 32],
    iconAnchor: [16, 16],
});
  

const parsePolygon = (area: Area): LatLng[] => {
    const polygon = area.polygon?.find(polygon => polygon.length);
    // shouldn't happen but just in case
    if(!polygon) return [];

    const points = polygon.split(' ');
    return points.map(point => {
        const coords = point.split(',');
        return new LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
    })
}


const parsePoint = (area: Area): LatLng => {
    const circle = area.circle?.find(circle => circle.length);
    // shouldn't happen but just in case
    if(!circle) return new LatLng(0, 0);

    const points = circle.split(' ');
    const coords = points[0].split(',');
    return new LatLng(parseFloat(coords[0]), parseFloat(coords[1]));
}


const AlertMap = ({
    alert,
}: Props) => {

    // find the polygon information if there is any
    const area_polygon = alert.info[0].area?.find(area => {
        return area.polygon !== null;
    });

    let center = new LatLng(56.1304, -106.3468);

    let polygon = null;
    if(area_polygon) {
        const coords = parsePolygon(area_polygon);
        // the center is the mean of the lat and long
        const lat = coords.reduce((total, next) => total + next.lat, 0);
        const lon = coords.reduce((total, next) => total + next.lng, 0);
        center = new LatLng(lat/coords.length, lon/coords.length);
        polygon = (
            <Polygon
                positions={coords}
                color="red"
                fillColor="red"
            />
        );
    }

    // we assume all circles to be a marker
    const area_point = alert.info[0].area?.find(area => {
        return area.circle !== null;
    });

    let marker = null;
    if(area_point) {
        center = parsePoint(area_point);
        marker = (
            <Marker
                icon={leafletEarthquakeIcon}
                position={center}
            />
        );
    }

    return (
        <CanadaMapContainer
            className="leaflet-container-headline-map"
            center={center}
            zoom={5}
            fullscreenControl={true}
            dragging={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            zoomControl={true}
        >
            {polygon}
            {marker}
            <ControlLegendEarthquake
                showMarker={marker !== null}
                showPolygon={polygon !== null}
            />
        </CanadaMapContainer>
    );
}
    
export default AlertMap;
