import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
    LanguageStateProvider,
    initialStateEnglish,
    initialStateFrench
} from './contexts/language-state-provider';

import {
    AppStateProvider,
    initialStateProduction,
    initialStateDevelopment,
} from './contexts/app-state-provider';


ReactDOM.render(
    <React.StrictMode>
        <LanguageStateProvider initialState={(document.documentElement.lang === 'fr') ? initialStateFrench : initialStateEnglish}>
            <AppStateProvider initialState={(process.env.NODE_ENV === 'development') ? initialStateDevelopment : initialStateProduction}>
                <App />
            </AppStateProvider>
        </LanguageStateProvider>
    </React.StrictMode>,
    document.getElementById('root-headline')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

