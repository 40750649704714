import './App.css';

import { useEffect } from 'react';

import { useAppState } from "./contexts/app-state-provider";

import ContainerHeadlines from './containers/container-headlines';

function App() {

    const [ , dispatch ] = useAppState();

    const baseUrl = document.getElementById('root-headline')?.getAttribute('data-url');
    
    useEffect(() => {
        if(baseUrl)
            dispatch({type: "SET_BASE_URL", payload: baseUrl})
    }, [baseUrl, dispatch])

    return <ContainerHeadlines />;
}

export default App;
