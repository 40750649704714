/**
 * @module components/maps/canada-map-container
 * @author Charles Blais <charles.blais@nrcan-nrcan.gc.ca>
 *
 * General map wrapper with Canada lambert projection.
 */

import React from 'react';

import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { MapOptions, LatLngExpression, LayersControlEvent } from 'leaflet';
import 'proj4leaflet';
import 'react-leaflet-fullscreen-control';

import { useLanguageState } from '../../contexts/language-state-provider';

// Constants
// We use basemap provided by geoappnext, these properties
// have been copied from the interface.
const projection_lcc = 'EPSG:3978'
const definition_lcc = '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=49 +lon_0=-95 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
const origin_lcc = [-3.46558E7, 3.931E7]
const resolutions_lcc = [
  38364.660062653464,
  22489.62831258996,
  13229.193125052918,
  7937.5158750317505,
  4630.2175937685215,
  2645.8386250105837,
  1587.5031750063501,
  926.0435187537042,
  529.1677250021168,
  317.50063500127004,
  185.20870375074085,
  111.12522225044451,
  66.1459656252646,
  38.36466006265346,
  22.48962831258996,
  13.229193125052918,
  7.9375158750317505,
  4.6302175937685215,
];

declare global {
  interface Window { L: any; }
}

window.L = window.L || {};

type Props = {
  children?: React.ReactNode,
  className?: string,
  projection?: '3978' | '3857',
  fullscreenControl?: boolean,
  layerControls?: any,
  onLayerAdd?: (layer: LayersControlEvent) => void,
  onLayerRemove?: (layer: LayersControlEvent) => void,
} & MapOptions

type PropsView = {
  center?: LatLngExpression,
  zoom?: number,
}

const ChangeView = ({ center, zoom }: PropsView) => {
  const map = useMap();
  if( center !== undefined && zoom !== undefined )
    map.setView(center, zoom);
  return null;
}

type PropsEvents = {
  onLayerAdd: (layer: LayersControlEvent) => void,
  onLayerRemove: (layer: LayersControlEvent) => void,
}

const MapEvents = ({
  onLayerAdd,
  onLayerRemove
}: PropsEvents) => {
  useMapEvents({
    overlayadd: (event: LayersControlEvent) => {
      onLayerAdd(event)
    },
    overlayremove: (event: LayersControlEvent) => {
      onLayerRemove(event)
    }
  })
  return null
}

/**
 * React component
 * @param param0 
 * @returns 
 */
const CanadaMapContainer = ({
  children,
  projection='3857',
  fullscreenControl=false,
  layerControls=undefined,
  onLayerAdd=() => {},
  onLayerRemove=() => {},
  ...props
}: Props) => {
  const [ lang ] = useLanguageState();

  const attribution = (lang.abbr === 'fr')?
    "&#169; Sa Majesté le Roi du Chef du Canada, représentée par le ministre des Ressources naturelles":
    "&#169; His Majesty the King in Right of Canada, as represented by the Minister of Natural Resources";
  const fgp_name = (lang.abbr === 'fr')?'CBCT':'CBMT';

  if (projection === '3978') {
    const crs = new window.L.Proj.CRS(
      projection_lcc,
      definition_lcc,
      {
        origin: origin_lcc,
        resolutions: resolutions_lcc,
      },
    );

    return (
      <MapContainer
        {...props}
        crs={crs}
        minZoom={2}
        maxZoom={resolutions_lcc.length - 1}
        continuousWorld={true}
        fullscreenControl={fullscreenControl}
      >
        <MapEvents onLayerAdd={onLayerAdd} onLayerRemove={onLayerAdd} />
        <ChangeView center={props.center} zoom={props.zoom} />
        <TileLayer attribution={attribution} url={'https://geoappext.nrcan.gc.ca/arcgis/rest/services/BaseMaps/CBMT_CBCT_GEOM_3978/MapServer/tile/{z}/{y}/{x}'} />
        {children}
      </MapContainer>
    );
  }

  return (
    <MapContainer
      {...props}
      minZoom={2}
      maxZoom={13}
      continuousWorld={true}
      fullscreenControl={fullscreenControl}
    >
      <MapEvents onLayerAdd={onLayerAdd} onLayerRemove={onLayerRemove} />
      <ChangeView center={props.center} zoom={props.zoom} />
      <TileLayer url={'https://geoappext.nrcan.gc.ca/arcgis/rest/services/BaseMaps/CBMT_CBCT_GEOM_3857/MapServer/tile/{z}/{y}/{x}'} />
      <TileLayer url={`https://geoappext.nrcan.gc.ca/arcgis/rest/services/BaseMaps/${fgp_name}_TXT_3857/MapServer/tile/{z}/{y}/{x}`} />
      {children}
    </MapContainer>
  );
}

export default CanadaMapContainer;
